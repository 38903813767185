(function (global, factory) {
	typeof exports === 'object' && typeof module !== 'undefined' ? module.exports = factory() :
		typeof define === 'function' && define.amd ? define(factory) :
			(global.VueEqualHeights = factory());
}(this, (function () {
	'use strict';

	function matchHeights(selectors, disabled) {
		selectors.forEach(setHeight);
	}

	function setHeight(selector) {
		//Prepare variables
		var elements = document.querySelectorAll(selector);

		var group = Array.prototype.slice.call(elements);

		//Loop though s
		var i = 0;
		while (group.length) {
			//Take current s and find others with same offset top, and eqh them.
			var queue = new Array();
			var templateOffset = group[i].getBoundingClientRect().y || group[i].getBoundingClientRect().top;
			for (var j = 0; j < group.length; j++) {
				var currentOffset = group[j].getBoundingClientRect().y || group[j].getBoundingClientRect().top;
				if (currentOffset === templateOffset) {
					queue.push(group[j]);
				}
			}
			//After eqhing, remove them from the loop, to avoid unnessesary loops
			eqhByGroup(queue);
			for (var k = 0; k < queue.length; k++) {
				group.splice(
					group.indexOf(queue[k])
					, 1);
			}
		}
	}

	function eqhByGroup(group) {
		var newHeightForGroup = 0;
		for (var i = 0; i < group.length; i++) {
			console.log(i + ' - ' + group[i].offsetHeight)
			if (group[i].offsetHeight > newHeightForGroup)
				newHeightForGroup = group[i].offsetHeight;
		}

		for (var i = 0; i < group.length; i++) {
			group[i].style.height = newHeightForGroup + 'px';
		}
	}

	function plugin(Vue, options = {}) {
		Vue.directive('equal-heights', {

			bind(el, binding) {
				function matchHeightsFunc() {
					matchHeights(binding.value.el, binding.value.disabled || options.disabled || []);
				}

				matchHeightsFunc();
				window.addEventListener('resize', matchHeightsFunc);
				Vue.nextTick(matchHeightsFunc);
			},

			inserted(el, binding) {
				function matchHeightsFunc() {
					matchHeights(binding.value.el, binding.value.disabled || options.disabled || []);
				}

				// Handle images rendering
				[].forEach.call(document.querySelectorAll(binding.value.el), (el) => {
					[].forEach.call(el.querySelectorAll('img'), (img) => {
						img.addEventListener('load', matchHeightsFunc);
					});
				});

				// Bind custom events to recalculate heights
				el.addEventListener('eqhheight', matchHeightsFunc, false);
				document.addEventListener('eqhheight', matchHeightsFunc, false);
			},

			unbind(el, binding) {
			}
		});
	}

	plugin.version = '0.1.0';

	if (typeof window !== 'undefined' && window.Vue) {
		window.Vue.use(plugin);
	}

	return plugin;

})));
