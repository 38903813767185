// Import our CSS
import styles from "../scss/app.scss";
import "swiper/css/swiper.css";

//Import vue components
import Vuex from "vuex";
import VueEqualHeights from "./plugins/EqualHeights";
import AOS from "aos";
import VueAwesomeSwiper from "vue-awesome-swiper";
import VueAgile from "vue-agile";
import * as VueGoogleMaps from "vue2-google-maps";
import GmapCluster from "vue2-google-maps/src/components/cluster";
import { mixin as clickaway } from "vue-clickaway";

// App main
const main = async () => {
  // Async load the vue module
  const { default: Vue } = await import(/* webpackChunkName: "vue" */ "vue");

  //Enable Equal Heights plugin
  Vue.use(VueEqualHeights);

  //Enable Vue swipers
  Vue.use(VueAgile);
  Vue.use(VueAwesomeSwiper);

  //Enable Vuex store
  Vue.use(Vuex);

  //Enable Googlemaps
  Vue.use(VueGoogleMaps, {
    load: {
      key: "AIzaSyBa6dywLTgpXB_IkzoIZl3k878HcFJG2ZI"
    }
  });

  Vue.component("GmapCluster", GmapCluster);

  //Vuex config and mutations
  const store = new Vuex.Store({
    state: {
      isMobileMenuOpen: false,
      isLanguageSelectorOpen: false,
      scrollPosition: 0,
      purchasableId: 0,
      isMounted: false,
      showSearch: false,
      showVideo: false,
      youtubeId: "",
      cartTotalQty: 0,
      overlayOpen: false,
      cart: [],
      isSlideInOpen: false
    },
    mutations: {
      closeMobileMenu(state) {
        state.isMobileMenuOpen = false;
      },
      openMobileMenu(state) {
        state.isMobileMenuOpen = true;
      },
      setSlideInState(state, value) {
        state.isSlideInOpen = value;
      },
      adjustScrollPosition(state, position) {
        state.scrollPosition = position;
      },
      setLanguageSelectorState(state, value) {
        state.isLanguageSelectorOpen = value;
      },
      setShowSearchState(state, value) {
        state.showSearch = value;
      },
      setOverlayState(state, value) {
        state.overlayOpen = value;
      },
      setShowVideoState(state, value) {
        state.showVideo = value;
      },
      setYoutubeIdState(state, value) {
        state.youtubeId = value;
      },
      setCartQty(state, value) {
        state.cartTotalQty = value;
      },
      setCart(state, value) {
        state.cart = value;
      }
    }
  });

  // Create our vue instance
  const vm = new Vue({
    el: "#app",
    delimiters: ["${", "}"],
    store,
    components: {
      AddressForm: () =>
        import(
          /* webpackChunkName: "AddressForm" */ "./components/AddressForm.vue"
        ),
      Accordion: () =>
        import(
          /* webpackChunkName: "Accordion" */ "./components/Accordion.vue"
        ),
      CategoryGrid: () =>
        import(
          /* webpackChunkName: "Categorypage" */ "./components/CategoryGrid.vue"
        ),
      NewsGrid: () =>
        import(/* webpackChunkName: "NewsGrid" */ "./components/NewsGrid.vue"),
      Cart: () =>
        import(/* webpackChunkName: "Cart" */ "./components/Cart.vue"),
      ChangePassword: () =>
        import(
          /* webpackChunkName: "Cart" */ "./components/ChangePassword.vue"
        ),
      CookiePopup: () =>
        import(
          /* webpackChunkName: "Cookiepopup" */ "./components/CookiePopup.vue"
        ),
      ContactForm: () =>
        import(
          /* webpackChunkName: "ContactForm" */ "./components/ContactForm.vue"
        ),
      ContactFormFile: () =>
        import(
          /* webpackChunkName: "ContactForm" */ "./components/ContactFormFile.vue"
        ),
      Customer: () =>
        import(/* webpackChunkName: "Customer" */ "./components/Customer.vue"),
      DocumentationPortal: () =>
        import(
          /* webpackChunkName: "DocumentationPortal" */ "./components/DocumentationPortal.vue"
        ),
      Product: () =>
        import(/* webpackChunkName: "AddToCart" */ "./components/Product.vue"),
      QtySpinner: () =>
        import(
          /* webpackChunkName: "QtySpinner" */ "./components/QtySpinner.vue"
        ),
      Search: () =>
        import(/* webpackChunkName: "Search" */ "./components/Search.vue"),
      ServiceFacilities: () =>
        import(
          /* webpackChunkName: "ServiceFacilities" */ "./components/ServiceFacilities.vue"
        ),
      Tabs: () =>
        import(/* webpackChunkName: "Tabs" */ "./components/Tabs.vue"),
      Tab: () => import(/* webpackChunkName: "Tab" */ "./components/Tab.vue"),
      YoutubePopup: () =>
        import(
          /* webpackChunkName: "YoutubePopup" */ "./components/YoutubePopup.vue"
        ),
      SiteMenu: () =>
        import(/* webpackChunkName: "SiteMenu" */ "./components/SiteMenu.vue"),
      SlideInMenu: () =>
        import(
          /* webpackChunkName: "SlideInOverlay" */ "./components/SlideInMenu.vue"
        ),
      SiteSelector: () =>
        import(
          /* webpackChunkName: "SiteSelector" */ "./components/SiteSelector.vue"
        )
    },
    mixins: [clickaway],
    data() {
      return {
        pageUrl: window.location.href,
        loggingIn: false,
        mapOptions: {
          styles: [
            {
              elementType: "geometry",
              stylers: [
                {
                  color: "#ecf8ff"
                }
              ]
            },
            {
              elementType: "geometry.stroke",
              stylers: [
                {
                  color: "#75848b"
                },
                {
                  visibility: "on"
                }
              ]
            },
            {
              elementType: "labels.icon",
              stylers: [
                {
                  visibility: "off"
                }
              ]
            },
            {
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#616161"
                }
              ]
            },
            {
              elementType: "labels.text.stroke",
              stylers: [
                {
                  color: "#f5f5f5"
                }
              ]
            },
            {
              featureType: "administrative.land_parcel",
              stylers: [
                {
                  color: "#ecf8ff"
                }
              ]
            },
            {
              featureType: "administrative.land_parcel",
              elementType: "labels",
              stylers: [
                {
                  visibility: "off"
                }
              ]
            },
            {
              featureType: "administrative.land_parcel",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#bdbdbd"
                }
              ]
            },
            {
              featureType: "administrative.neighborhood",
              stylers: [
                {
                  visibility: "off"
                }
              ]
            },
            {
              featureType: "landscape",
              elementType: "geometry.fill",
              stylers: [
                {
                  color: "#ecf8ff"
                }
              ]
            },
            {
              featureType: "poi",
              elementType: "geometry",
              stylers: [
                {
                  color: "#eeeeee"
                }
              ]
            },
            {
              featureType: "poi",
              elementType: "labels.text",
              stylers: [
                {
                  visibility: "off"
                }
              ]
            },
            {
              featureType: "poi",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#757575"
                }
              ]
            },
            {
              featureType: "poi.business",
              stylers: [
                {
                  visibility: "off"
                }
              ]
            },
            {
              featureType: "poi.medical",
              stylers: [
                {
                  visibility: "off"
                }
              ]
            },
            {
              featureType: "poi.park",
              elementType: "geometry",
              stylers: [
                {
                  color: "#ecf8ff"
                }
              ]
            },
            {
              featureType: "poi.park",
              elementType: "labels.text",
              stylers: [
                {
                  visibility: "off"
                }
              ]
            },
            {
              featureType: "poi.park",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#9e9e9e"
                }
              ]
            },
            {
              featureType: "poi.place_of_worship",
              stylers: [
                {
                  visibility: "off"
                }
              ]
            },
            {
              featureType: "poi.school",
              stylers: [
                {
                  visibility: "off"
                }
              ]
            },
            {
              featureType: "road",
              elementType: "geometry",
              stylers: [
                {
                  color: "#ffffff"
                }
              ]
            },
            {
              featureType: "road.arterial",
              elementType: "labels",
              stylers: [
                {
                  visibility: "off"
                }
              ]
            },
            {
              featureType: "road.arterial",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#757575"
                }
              ]
            },
            {
              featureType: "road.highway",
              elementType: "geometry",
              stylers: [
                {
                  color: "#cae0ed"
                }
              ]
            },
            {
              featureType: "road.highway",
              elementType: "labels",
              stylers: [
                {
                  visibility: "off"
                }
              ]
            },
            {
              featureType: "road.highway",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#616161"
                }
              ]
            },
            {
              featureType: "road.local",
              stylers: [
                {
                  visibility: "off"
                }
              ]
            },
            {
              featureType: "road.local",
              elementType: "labels",
              stylers: [
                {
                  visibility: "off"
                }
              ]
            },
            {
              featureType: "road.local",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#9e9e9e"
                }
              ]
            },
            {
              featureType: "transit.line",
              elementType: "geometry",
              stylers: [
                {
                  color: "#cae0ed"
                }
              ]
            },
            {
              featureType: "transit.station",
              elementType: "geometry",
              stylers: [
                {
                  color: "#eeeeee"
                }
              ]
            },
            {
              featureType: "water",
              elementType: "geometry",
              stylers: [
                {
                  color: "#b8cedb"
                }
              ]
            },
            {
              featureType: "water",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#9e9e9e"
                }
              ]
            }
          ],
          streetViewControl: false,
          mapTypeControl: false,
          fullscreenControl: false,
          maxZoom: 24,
          scroll_position: 0,
          scroll_direction: ""
        }
      };
    },
    methods: {
      handleScroll: function(event) {
        if (store.state.overlayOpen) {
          return;
        }

        this.scroll_direction =
          document.body.getBoundingClientRect().top > this.scroll_position
            ? "up"
            : "down";
        this.scroll_position = document.body.getBoundingClientRect().top;

        const header = document.getElementById("header");
        let scrollPosY = window.pageYOffset || document.body.scrollTop;

        if (this.scroll_direction === "down") {
          if (scrollPosY > 40) {
            header.classList.add("scrolled");
          }
        } else if (this.scroll_direction === "up") {
          header.classList.remove("scrolled");
        }

        // const app = document.getElementById('app')
        // if (scrollPosY > 35) {

        // 	if (!app.classList.contains('scrolled')) {
        // 		app.classList.add('scrolled')
        // 	}

        // } else if (scrollPosY <= 35) {
        // 	app.classList.remove('scrolled')
        // }

        store.commit("adjustScrollPosition", scrollPosY);

        //Close language selector
        switch (store.state.isLanguageSelectorOpen) {
          case true:
            store.commit("setLanguageSelectorState", false);
            break;

          default:
            store.commit("setLanguageSelectorState", false);
            break;
        }
      },
      toggleLanguageSelectorState: function() {
        switch (store.state.isLanguageSelectorOpen) {
          case true:
            store.commit("setLanguageSelectorState", false);
            break;

          case false:
            store.commit("setLanguageSelectorState", true);
            break;

          default:
            store.commit("setLanguageSelectorState", false);
            break;
        }
      },
      closeLanguageSelector: function() {
        if (store.state.isLanguageSelectorOpen) {
          store.commit("setLanguageSelectorState", false);
        }
      },
      slideTo(event, ref) {
        let slider = this.$refs[ref];
        slider.goTo(event.nextSlide);
      },
      toggleSearch() {
        store.commit("setShowSearchState", !store.state.showSearch);
        this.toggleScroll(store.state.showSearch);
      },
      toggleScroll(active) {
        let html = document.getElementById("html");
        html.classList.toggle("no-scroll");

        if (active) {
          html.style.top = `-${store.state.scrollPosition}px`;
          store.commit("setOverlayState", true);
        } else {
          window.scrollTo(0, store.state.scrollPosition);
          store.commit("setOverlayState", false);
        }
      },
      openVideo(youtubeId) {
        store.commit("setYoutubeIdState", youtubeId);
        this.toggleVideo();
      },
      toggleVideo() {
        store.commit("setShowVideoState", !store.state.showVideo);
      },
      updateCart() {
        this.$refs.cart.updateCart();
      },
      checkInput(element) {
        console.log(element);
      },
      openContactForm() {
        this.$refs.contactform.slideOut();
      },
      openSlideIn() {
        store.commit("setSlideInState", true);
      },
      toggleMobileElement(e) {
        e.target.parentNode.classList.toggle("open");
      },
      async validateCustomer(e) {
        await this.$refs["customer"].validateForm().then(isValid => {
          if (!isValid) {
            e.preventDefault();
            return false;
          }
        });
      }
    },
    computed: {
      isSlideInOpen() {
        return store.state.isSlideInOpen;
      },
      isMobileMenuOpen() {
        return store.state.isMobileMenuOpen;
      },
      isLanguageSelectorOpen() {
        return store.state.isLanguageSelectorOpen;
      },
      showSearch() {
        return store.state.showSearch;
      },
      showVideo() {
        return store.state.showVideo;
      },
      videoId() {
        return store.state.youtubeId;
      },
      cartQty() {
        return store.state.cart.totalQty;
      },
      cart() {
        return store.state.cart;
      },
      isMounted() {
        return store.state.isMounted;
      }
    },
    created() {
      AOS.init({
        easing: "ease-in-out",
        delay: 100,
        once: true,
        startEvent: "load",
        anchorPlacement: "top-bottom"
      });

      window.addEventListener("scroll", this.handleScroll, { passive: true });
    },
    destroyed() {
      window.removeEventListener("scroll", this.handleScroll);
    },
    beforeMount: function() {
      store.commit("setCart", window.siteData.cart);
    },
    mounted() {
      this.$nextTick(function() {
        store.state.isMounted = true;
      });
    }
  });

  return vm;
};

// Execute async function
main().then(vm => {});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
  module.hot.accept();
}
